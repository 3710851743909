<template>
  <div class="poem">
    <!-- <div class="poem__content">
      <div><img v-lazy="messageIcon" alt="mess-icon" /></div>
      <h3>{{ poem.title }}</h3>
      <div>
        <img
          v-lazy="messageIcon"
          style="transform: rotate(-180deg)"
          alt="mess-icon"
        />
      </div>
    </div> -->
    <div class="poem__wrapper">
      <div class="poem__techstack">
        <div class="poem__techstack--wrapper">
          <div class="slideshow">
            <div class="move hashtag">
              <div
                v-for="(_item, indx) in [1, 2, 3, 4]"
                :key="indx"
                class="poem__hashtag__items"
              >
                <div
                  v-for="(item, index) in data"
                  :key="index"
                  class="poem__hashtag__item"
                >
                  <img v-lazy="item.icon" alt="hashtag-icon" />
                  <span class="text-nowrap">{{ item.hashtag }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="poem__techstack--wrapper people">
          <div class="slideshow">
            <div class="move">
              <div
                v-for="(_item, indx) in [1, 2, 3, 4]"
                :key="indx"
                class="poem__people__items"
              >
                <div
                  v-for="(item, index) in poems"
                  :key="index"
                  class="poem__people__item"
                >
                  <img v-lazy="item" alt="poem-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="poem__techstack--wrapper people d-sm-none d-flex">
          <div
            v-for="(_item, indx) in [1, 2, 3, 4]"
            :key="indx"
            class="poem__people__items"
          >
            <div
              v-for="(item, index) in poems"
              :key="index"
              class="poem__people__item"
            >
              <img v-lazy="item" alt="poem-icon" />
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'poem',
  data() {
    return {
      messageIcon: '/images/icons/aboutus/message.svg',
      poem: this.$t('recruiment').poem,
      data: [
        {
          hashtag: 'teamwork',
          icon: '/images/icons/home/teamwork.svg',
        },
        {
          hashtag: 'happy hour',
          icon: '/images/icons/home/happyhour.svg',
        },
        {
          hashtag: 'birthday party',
          icon: '/images/icons/home/birthday.svg',
        },
        {
          hashtag: 'grow up together',
          icon: '/images/icons/home/teamwork.svg',
        },

        {
          hashtag: 'the first penguin',
          icon: '/images/icons/home/happyhour.svg',
        },
        {
          hashtag: 'smart',
          icon: '/images/icons/home/birthday.svg',
        },
        {
          hashtag: 'speed',
          icon: '/images/icons/home/happyhour.svg',
        },
        {
          hashtag: 'people',
          icon: '/images/icons/home/birthday.svg',
        },
      ],
      poems: [
        '/images/home/memory1.webp',
        '/images/home/memory2.webp',
        '/images/home/memory3.webp',
        '/images/home/memory4.webp',
        '/images/home/memory5.webp',
        '/images/home/memory6.webp',
        '/images/home/memory7.webp',
        '/images/home/memory8.webp',
        '/images/home/memory9.webp',
        '/images/home/memory10.webp',
      ],
    };
  },
  watch: {
    lang() {
      this.poem = this.$t('recruiment').poem;
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
};
</script>

<style lang="scss" scroped>
.poem {
  width: 100%;
  padding: 50px 0;
  .poem__content {
    display: flex;
    text-align: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 70px;
    max-width: 60%;
    & > img {
      width: 54px;
      object-fit: cover;
    }
    & > h3 {
      margin: 0;
      font-weight: 400;
      font-size: 36px;
      padding: 0 10px;
      color: var(--grey-text);
    }
  }
  .poem__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 100px;
    width: 100%;
    height: 100%;
  }
  .poem__techstack {
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 36px;
    .poem__techstack--wrapper {
      display: flex;
      gap: 36px;
    }
    .slideshow {
      margin: 0 auto;
      position: relative;
      transform: translate3d(0, 0, 0);
      display: flex;
      gap: 36px;
      flex: 1;
      width: 900px;
      overflow: hidden;
    }
    .poem__techstack--wrapper.people {
      .slideshow,
      .move {
        gap: 24px;
      }
    }
    .poem__hashtag__items {
      display: flex;
      gap: 24px;
    }
    .poem__hashtag__item {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ebf2ff;
      gap: 8px;
      width: max-content;
      padding: 4px 16px;
      border-radius: 10px;
      svg {
        transform: scale(0.6);
      }
    }
    .poem__hashtag__item:first-child {
      width: 160px;
    }
    .poem__hashtag__item:nth-child(2) {
      width: 170px;
    }
    .poem__hashtag__item:nth-child(3) {
      width: 190px;
    }
    .poem__hashtag__item:nth-child(4) {
      width: 210px;
    }
    .poem__hashtag__item:nth-child(5) {
      width: 210px;
    }
    .poem__hashtag__item:nth-child(6) {
      width: 130px;
    }
    .poem__hashtag__item:nth-child(7) {
      width: 130px;
    }
    .poem__hashtag__item:last-child {
      width: 130px;
    }

    .poem__people__items {
      display: grid;
      grid-template-areas:
        '_1 _2 _2 _3 _4 _5 _6'
        '_7 _7 _8 _3 _9 _10 _10';
      gap: 24px;
      grid-template-rows: 200px 200px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .poem__people__item:first-child {
      grid-area: _1;
      width: 200px;
    }
    .poem__people__item:nth-child(2) {
      grid-area: _2;
      width: 362px;
    }
    .poem__people__item:nth-child(3) {
      grid-area: _3;
      width: 200px;
    }
    .poem__people__item:nth-child(4) {
      grid-area: _4;
      width: 362px;
    }
    .poem__people__item:nth-child(5) {
      grid-area: _5;
      width: 200px;
    }
    .poem__people__item:nth-child(6) {
      grid-area: _6;
      width: 200px;
    }
    .poem__people__item:nth-child(7) {
      grid-area: _7;
      width: 362px;
    }
    .poem__people__item:nth-child(8) {
      grid-area: _8;
      width: 200px;
    }
    .poem__people__item:nth-child(9) {
      grid-area: _9;
      width: 362px;
    }
    .poem__people__item:last-child {
      grid-area: _10;
      width: 424px;
    }
    .poem__hashtag__item img {
      width: 30px;
      height: 30px;
      object-fit: contain;
    }

    .move {
      transform: translate3d(0, 0, 0);
      display: flex;
      gap: 24px;
      transition: all 0.2s linear;
      width: calc(1644px * 4 + 24px * 3);
      animation: scrollX 200s linear infinite;
    }
    .move.hashtag {
      width: calc(1498px * 4 + 12px * 3);
      animation: scrollXHashtag 200s linear infinite;
    }
    --slider-inner-width: 1644px;
    --slider-hashtag-width: 1498px;
    --slider-speed: 6s;
    @keyframes scrollX {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(
          calc(0px - var(--slider-inner-width) * 2 - 24px * 2)
        );
      }
    }
    @keyframes scrollXHashtag {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(
          calc(0px - var(--slider-hashtag-width) * 2 - 24px * 2)
        );
      }
    }
  }
  @media (max-width: 768px) {
    .poem__content {
      max-width: 80%;
      h3 {
        font-size: 24px;
      }
    }
    /* .poem__people__items {
      grid-template-areas:
        '_1 _2 _2 _3'
        '_7 _7 _8 _3'
        '_4 _4 _5 _6'
        '_9 _9 _10 _10' !important;
      gap: 10px !important;
      grid-template-rows: 100px 100px !important;
    }
    .poem__people__item:first-child {
      width: 93px !important;
    }
    .poem__people__item:nth-child(2) {
      width: 154px !important;
    }
    .poem__people__item:nth-child(3) {
      width: 128px !important;
    }
    .poem__people__item:nth-child(4) {
      width: 173px !important;
    }
    .poem__people__item:nth-child(5) {
      width: 102px !important;
    }
    .poem__people__item:nth-child(6) {
      width: 102px !important;
    }
    .poem__people__item:nth-child(7) {
      width: 123px !important;
    }
    .poem__people__item:nth-child(8) {
      width: 123px !important;
    }
    .poem__people__item:nth-child(9) {
      width: 173px !important;
    }
    .poem__people__item:last-child {
      width: 214px !important;
    } */
  }
}
</style>
